import React from "react";
import { Link } from "react-router-dom";
import { format } from "../../components/number-format/number-format.component";
import { lookUP, save, saveChanges } from "../../api/functions";
import Switch from "@material-ui/core/Switch";

export const insertWithdrawal = (input, api_token, setResponse) => {
  //alert(api_token);

  save("withdraw", { inputs: input }, api_token, (responseJson) => {
    console.log(responseJson);
    //  return false;
    if (responseJson.success === true) {
      setResponse(responseJson.data, "success");

      //getFunctions.getFunctions();
    } else {
      setResponse(responseJson.data, "danger");
    }
  });
};

export const getHomeMembers = (api_token, setMembers) => {
  lookUP(
    "addTeam",
    { inputs: { page: "agentSummaryDashboard", web: "web" } },
    api_token,
    (responseJson) => {
      // console.log(responseJson);
      //  return false;
      try {
        setMembers(responseJson.agent_summary_data);
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const getPaymentHistory = (api_token, setWithdraw) => {
  let i = 0;

  let obj = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 270,
      },
      {
        label: "Agent Name",
        field: "agent_name",
        width: 270,
      },
      {
        label: "Client Name",
        field: "client_name",
        width: 270,
      },
      {
        label: "Amount",
        field: "amount",
        width: 270,
      },
      {
        label: "Commission Type",
        field: "commission_type",
        width: 270,
      },
      {
        label: "Client Payment",
        field: "client_payment",
        width: 270,
      },
      {
        label: "Status",
        field: "effect",
        width: 270,
      },
    ],
    rows: [],
  };

  save(
    "withdraw",
    { inputs: { page: "agentEarnings", web: "web" } },
    api_token,
    (responseJson) => {
      console.log(responseJson);
      //   return false;
      try {
        if (responseJson.table_data !== "") {
          responseJson.table_data.map((withdraw) => {
            i += 1;
            obj.rows.push({
              id: i,
              agent_name: withdraw.agent_name,
              client_name: withdraw.client_name,
              amount: format(withdraw.amount, 2),
              commission_type: withdraw.commission_type,
              client_payment: format(withdraw.client_amount, 2),
              effect: withdraw.effect,
            });
          });
        } else {
          setWithdraw("");
        }

        // alert('yes');
        setWithdraw(
          obj,
          responseJson.wallet_balance,
          responseJson.total_earnings,
          responseJson.total_withdraw,
          responseJson.pending_withdraw
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const transactionHistory = (api_token, setHistory) => {
  let i = 0;

  let obj = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 270,
      },

      {
        label: "Date",
        field: "date",
        width: 270,
      },

      {
        label: "Description",
        field: "description",
        width: 270,
      },

      {
        label: "Debit",
        field: "debit",
        width: 270,
      },
      {
        label: "Credit",
        field: "credit",
        width: 270,
      },

      {
        label: "Balance",
        field: "balance",
        width: 270,
      },
    ],
    rows: [],
  };

  save(
    "withdraw",
    { inputs: { page: "agentWithdrawalHistory", web: "web" } },
    api_token,
    (responseJson) => {
      // console.log(responseJson);
      //  return false;
      try {
        if (responseJson !== "") {
          responseJson.map((history) => {
            i += 1;
            obj.rows.push({
              id: i,
              description: history.description,
              debit: history.dr_amount,
              credit: history.cr_amount,
              date: history.date,
              balance: history.bal,
            });
          });
        } else {
          setHistory("");
        }

        // alert('yes');
        setHistory(obj);
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const getDetails = (api_token, setTeam, setAtion, disableAgents) => {
  let cus = 0;
  let i = 0;

  let obj = {
    columns: [
      { label: "#", field: "id", width: 270 },

      {
        label: "Name",
        field: "name",
        width: 270,
      },
      {
        label: "Phone",
        field: "phone",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        width: 270,
      },

      {
        label: "Ref ID",
        field: "ref_id",
        width: 270,
      },

      {
        label: "Gender",
        field: "gender",
        width: 270,
      },
      {
        label: "Current Earning",
        field: "current_earning",
        width: 270,
      },
      {
        label: "Signup",
        field: "account",
        width: 270,
      },
      {
        label: "Enable",
        field: "disable",
        width: 270,
      },
      {
        label: "Assign Admin",
        field: "action",
        width: 270,
      },
    ],
    rows: [],
  };

  lookUP(
    "addTeam",
    { inputs: { page: "getTeamMembers", web: "web" } },
    api_token,
    (responseJson) => {
      // console.log(responseJson);
      // return false;
      try {
        responseJson.table_data.map((teamAcc) => {
          i += 1;
          obj.rows.push({
            id: i,
            name: (
              <Link to={`/memberAccount/${teamAcc.agent_ref_id}`}>
                {teamAcc.name}
              </Link>
            ),
            phone: teamAcc.phone,
            email: teamAcc.email,
            ref_id: teamAcc.agent_ref_id,
            current_earning: teamAcc.cuurent_earning,
            account: teamAcc.account,
            gender: teamAcc.gender,
            current_earning: teamAcc.current_earning,
            disable: (
              <Switch
                checked={teamAcc.status == 1 ? false : true}
                onChange={() =>
                  disableAgents(
                    teamAcc.agent_id,
                    teamAcc.status,
                    teamAcc.team_id
                  )
                }
                color="secondary"
              />
            ),
            action: (
              <Switch
                checked={teamAcc.is_admin == 1 ? true : false}
                onChange={() =>
                  setAtion(teamAcc.agent_id, teamAcc.is_admin, teamAcc.team_id)
                }
                color="primary"
              />
            ),
          });
          cus += 1;
        });

        setTeam(
          obj,
          responseJson.team_info_data,
          responseJson.team_summary_data
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
};
